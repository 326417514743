import React, {Component} from "react";
import {Grid, Image} from "semantic-ui-react";
import ResetPasswordForm1 from "../components/forms/reset-password-form/reset-password-form1";
import "../styles/bg.css";
import {formatQuery} from "../controllers/utilities/string-utils";
import cookie from "react-cookies";
import {navigate} from "../../.cache/gatsby-browser-entry";
import NavigationBar from "../components/forms/navigation-bar";

export default class extends Component {
    render() {
        let {back} = formatQuery(this.props.location.search);
        const _login_gameId = cookie.load("_login_gameId");
        const _login_attributionId = cookie.load("_login_attributionId");
        const mode = detectOrient();
        if (typeof window !== `undefined`) {
            var docEl = document.documentElement;
            var clientWidth = docEl.clientWidth;
            if (!clientWidth) return;
            if (mode === "PORTRAIT"){
                docEl.style.fontSize = 14 * (clientWidth / 450) + 'px';
            }else{
                // docEl.style.fontSize = 14 * (clientWidth / 900) + 'px';
            }              
        }
        return (
            <div className="whiteDiv" textAlign='center' style={{
                width:"100%",
                minHeight: "100vh",
                textAlign:"center",
                margin:"0 auto"
            }} >

<NavigationBar mode={mode} platform={"h5"} showLogo={1} showTitle={4} onBack={() => {
                                if (back === undefined){
                                    navigate(`/login?gameId=${_login_gameId}&attributionId=${_login_attributionId}`);
                                }else{
                                    navigate(`/sdk-callback`);
                                }
                            }}/>

                <div style={{maxWidth: `${(mode === "PORTRAIT")?"450px":"360px"}`, height:"100vh", paddingTop:"80px", margin:"0 auto"}}>
                    <div style={{padding:"5vh"}}>
                    <ResetPasswordForm1 back={back}/>
                    </div>
                </div>
            </div>
        );
    }
}

function detectOrient() {
    if (typeof window !== `undefined`) {
        // let isIOS = "0";
        // if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) {  //判断iPhone|iPad|iPod|iOS
        //     // 暂时去掉ios两板功能
        //     isIOS = "1";
        // }
        // if (isIOS === "0") return "PORTRAIT";
        if (window.innerHeight >= window.innerWidth) {
            // 竖屏
            // console.log("竖屏");
            return "PORTRAIT";
        } else {
            // 横屏
            // console.log("横屏");
            return "LANDSCAPE";
        }
    } else {
        return "PORTRAIT";
    }
}