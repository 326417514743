import React, {Component} from "react";
import KaisakuApi, {getFullPath} from "../../../controllers/kaisaku-api";
import View from "./reset-password-form-view1";
import withTrans from "../../../i18n/withTrans";
import {formatQuery} from "../../../controllers/utilities/string-utils";
import Lambda from "../../../controllers/utilities/aws-lambda";
import cookie from "react-cookies";
import {getProfile} from "../../../pages/game-support";

class ResetPasswordForm extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            newPassWord: "",
            newPassWord2: "",
            errorMsgs: [],
            successMsgs: "",
            loading: false,
            success: false,
            accessToken: ""
        };
    }

    async componentDidMount(){
        // 獲取用戶資料
        const accessToken = cookie.load("_accessToken");
        this.setState({
            accessToken: accessToken
        });
    }

    onPasswordChange(e, data) {
        this.setState({newPassWord: data.value});
    }
    onPassword2Change(e, data) {
        this.setState({newPassWord2: data.value});
    }

    async changePasswordSDK(newPassword, accessToken) {
        return await Lambda.post(getFullPath("changePasswordSDK"), {newPassword}, accessToken);
    }

    async onSubmit() {
        this.setState({
            success: false,
            errorMsgs: [],
            successMsgs: ""
        });
        console.log(this.props);
        let msg = "";
        let success = false;
        if ((this.state.newPassWord === "") || (this.state.newPassWord2 === "")){
            msg = "新密碼與確認新密碼不能爲空";
        } else if (this.state.newPassWord !== this.state.newPassWord2){
                msg = "新密碼與確認新密碼不一致";
        }else{
            // 进行修改密码的操作
            const password = this.state.newPassWord;
            if (validatePassWd(password)){
                // 发送进行保存密码
                const res = await this.changePasswordSDK(this.state.newPassWord,this.state.accessToken);
                if (res.status === 200) {
                    success = true;
                    msg = "密碼保存成功";
                }else{
                    msg = "密碼保存失敗";
                }

            }else{
                msg = "密碼格式爲 英文或數字，長度在8-16之間";
            }
        }
        let errorMsgs = [];
        let successMsgs = "";
        if (success){
            successMsgs = msg;
        }else{
            errorMsgs.push(msg);
        }

        this.setState({
            success: success,
            errorMsgs: errorMsgs,
            successMsgs: successMsgs
        });
        console.log("结束");
        // const res = await KaisakuApi.resetPassword(this.state.username);
        // this.processResult(res);
    }

    processResult(result) {
        if(result.success) {
            this.setState({
                loading: false,
                success: true
            });
        } else {
            this.setState({
                loading: false,
                success: result.success,
                errorMsgs: result.errorMsgs.map(this.props.t)
            });
        }
    }

    render() {
        return (
            <View
                loading={this.state.loading}
                success={this.state.success}
                errorMsgs={this.state.errorMsgs}
                successMsgs={this.state.successMsgs}
                onPasswordChange={this.onPasswordChange.bind(this)}
                onPassword2Change={this.onPassword2Change.bind(this)}
                onSubmit={this.onSubmit.bind(this)}
                back={this.props.back}
            />
        );
    }

}

export default withTrans(ResetPasswordForm);

function validatePassWd(password) {
    const str = password;
    if (str == null || str.length < 8 || str.length > 16) {
        return false;
    }
    const reg1 = new RegExp(/^[0-9A-Za-z]+$/);
    if (!reg1.test(str)) {
        return false;
    }
    return getPasswordErrors(password);
    // const reg = new RegExp(/[A-Za-z].*[0-9]|[0-9].*[A-Za-z]/);
    // if (reg.test(str)) {
    //     return true;
    // } else {
    //     return false;
    // }
}

function getPasswordErrors(password) {
    if (startsWithSpace(password)) {
        return false;
    }
    if (!sixCharsOrMore(password)) {
        return false;
    }
    if (!containsOnlyLegalChars(password)) {
        return false;
    }
    return true;
}

function startsWithSpace(str) {
    const startsWithSpace = /^ /g;
    return startsWithSpace.test(str);
}

function sixCharsOrMore(str) {
    const sixCharsOrMore = /.{6,}/g;
    return sixCharsOrMore.test(str);
}

function containsOnlyLegalChars(str) {
    const containsOnlyLegalChars = /^(?=.*[A-Za-z\d @$!%*#?&^+])[A-Za-z\d @$!%*#?&^+]*$/g;
    return containsOnlyLegalChars.test(str);
}